/* -------------------------- RESET ----------------------------------- */
html, body, address, blockquote, div, dl, form, h1, h2, h3, h4, h5, h6,

ol, p, pre, table, ul, dd, dt, li, tbody, td, tfoot, th, thead, tr,

button, del, ins, map, object, a, abbr, acronym, b, bdo, big, br, cite,

code, dfn, em, i, img, kbd, q, samp, small, span, strong, sub, sup, tt,

var, legend, fieldset {
    margin: 0;
    padding: 0;
}

@font-face {
    font-family: 'Oswald';

    src: url('fonts/oswald/static/Oswald-Medium.ttf') format('truetype');

    font-weight: normal;

}

@font-face {
    font-family: 'Oswald-ExtraLight';

    src: url('fonts/oswald/static/Oswald-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Oswald-Bold';

    src: url('fonts/oswald/static/Oswald-Bold.ttf') format('truetype');

}

@font-face {
    font-family: 'Oswald-SemiBold';

    src: url('fonts/oswald/static/Oswald-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Oswald-Regular';

    src: url('fonts/oswald/static/Oswald-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Oswald-Light';

    src: url('fonts/oswald/static/Oswald-Light.ttf') format('truetype');
}


@font-face {
    font-family: 'Roboto';

    src: url('fonts/roboto/Roboto-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto-Thin';

    src: url('fonts/roboto/Roboto-Thin.ttf') format('truetype');


}

@font-face {
    font-family: 'Roboto-Regular';

    src: url('fonts/roboto/Roboto-Regular.ttf') format('truetype');

}

@font-face {
    font-family: 'Roboto-MediumItalic';

    src: url('fonts/roboto/Roboto-MediumItalic.ttf') format('truetype');

}


/*                            GENERAL STYLING

------------------------------------------------------------------------------- */
html {
    height: 100%;
    -webkit-text-size-adjust: none;
}
body {
    height: 100%;
    min-height: 100%;
    color: #666;
    background: #fff;
    font: 12pt/1.4 Oswald-Light, oswald, sans-serif
}

h1 {
    margin-bottom: .8em;
    font: 30pt/1.4 Oswald-SemiBold, sans-serif;
}

h2 {
    margin-bottom: .8em;
    font: 24pt/1.4 Oswald, sans-serif;
}

h3 {
    margin-bottom: 5px;
    font: 18pt/1.4 Oswald, sans-serif;
}

.carousel,
.item,
.active {
    height: 100%;
}

h2 {
    text-align: center !important;
}

.pad {
    padding: 3rem !important;
}

.about-container, .zdravec-water-content-text {
    font-size: 14pt;
}

/** navigation **/
button:focus,.button:active {
    outline: none !important;
    box-shadow: none;
    background-color: #FFFDFB !important;
}

/* transparent navigation */
/* enable for navigation */
/*
.trn {
    background: transparent !important;
    z-index: 999;
}
*/
#navbar-nav-links a {
    margin-right: 30px;
    color: #285a5b !important;
    border-bottom: 2px solid transparent;
    text-transform: uppercase;
    font-size: 14pt;
    font-family: Oswald-ExtraLight, Oswald, sans-serif;
}
#navbar-nav-links a:hover {
    border-bottom: 2px solid #285a5b;
}
#collasible-nav-dropdown {
    text-transform: uppercase;
}

div.navbar-nav a.active {
    border-bottom: 2px solid #285a5b !important;
}

#collasible-nav-dropdown {
    color: #285a5b !important;
}
#dropdown nav-item a {
    color:grey !important;
}
.dropdown-menu{min-width:0;}
.dropdown-menu .dropdown-item{white-space:normal;}
.navbar-nav {
    margin: 0;
    padding: 7.5px 15px;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: #285a5b !important;
}
/*** Slider Sestion **/
.carousel-caption {
    /*margin-bottom: 27%; */
    padding: 50px 100px;
    width: 70%;
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
}


.carousel-caption h1 {
    font-style: normal;
    font-variant: normal;
    font-size: 92pt;
    line-height: 1.4em;
    font-family: Oswald-ExtraLight, Oswald, sans-serif;

    text-align: center;
    letter-spacing: 0.05em;
    font-weight: lighter;
    margin-top: 30px;
    margin-bottom: 30px
}
.zdravec-slider-line {
    transform-origin:center 1px;
    width:796px;
    height:5px;
    position: relative;
    margin: 0px 0px 10px calc((100% - 980px) * 0.5);
    left: 91px;
    grid-area: 2 / 1 / 3 / 2;
    justify-self: start;
    align-self: start;
    ox-sizing: border-box;
    border-top: 2px solid rgba(255, 253, 251, 1);
    height: 0;
}
.zdravets-slogan  {
    letter-spacing: 0.05em;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: 0 0;
    color: #FFFDFB;
    text-align: center;



    font-size: 20pt;
    line-height: 1.4em;
    font-family: Oswald-SemiBold, Oswald, Sans-Serif;

}

.zdravec-since {
    letter-spacing: 0.05em;
    color: #FFFDFB;
    font-size: 21pt;
}

.navbar-custom {
    background: linear-gradient(silver, 5%, #fffdfb) !important;
}
/** Fix img to fit screen height start */
/* works but brakes inside div content positioning and also uses overflow hidden
.carousel {
    height: 100vh;
    width: 100%;
    overflow:hidden;
}
.carousel .carousel-inner {
    height:100%;
}
*/

.carousel-inner {
    height: 100%;
}

/* Background images are set within the HTML using inline CSS, not here */

.fill {
    width: 100%;
    height: 100%;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
}
.parallax {
    /** paralax **/
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* Move the pseudo-element back away from the camera,
     * then scale it back up to fill the viewport.
     * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
    transform: translateZ(-1px) scale(1.048);
}

/* ===== Style for smartphone and iPad in portrait & landscape== =  */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 1024px)  {
   .noparallax {
        background-attachment: unset !important;
        background-position: center top !important; }
}

.slider-img {
    height: 86vh; /* 100 for full height slider combined with fixed top navigation and trn class */
    min-height: 350px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
#slider-img-first {
    background-image: url("../img/zdravec/used-img/home-slider/wheat-sky.jpg");
}

#slider-img-second {
    background-image: url("../img/zdravec/used-img/home-slider/wheat-black.jpg");
}

#slider-img-third {
    background-image: url("../img/zdravec/used-img/home-slider/ayran.jpg");
}

/** Fix img to fit screen height end */
.carousel-caption h3 {
    text-transform: uppercase;
}
.drk:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    opacity: 0.3;
}
/** Slider background dimmed **/
.grn:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #285a5b;
    opacity: 0.1;
}
.ccd {
    background: rgba(0,0,0,0.6);
}

/** CARDS **/
.zdravec-products-colored {
    /* background-color: #ffb84d; */
    height: 100%;
    width: 100%;
    color: #285a5b;
}
h2.card-title {
    text-transform: capitalize;
    font-family: Oswald-Regular, Oswald, sans-serif, Oswald, sans-serif;
    font-size: 22pt;
}

.card a {
    color: #285a5b;
}

.card, .card-header, .card-body, .card-footer, .card-img {
    border-radius:0px !important;
    border: none;
}

.card p {
    font: normal normal lighter 13pt/1.4em Roboto-Regular,roboto,sans-serif;
    line-height: 1.8em;
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    vertical-align: baseline;
    background: 0 0;
    /*white-space: nowrap;*/
}
.card-text a {
    /*border-bottom: 2px solid #1f9151 !important;*/
    border-bottom: 2px solid #285a5b !important;
    padding-right: .5rem;
    padding-left: .5rem;
    padding: .5rem 1rem;
    letter-spacing:0.05em;
    font-weight: lighter;

}
.card-text a:hover {
    text-decoration: none;
}
.section-card {
    width: 96%;
    margin:0 auto;
    margin-top: 2%;
    margin-bottom: 2%;
}
/*
.card-group {
    min-height: 767px !important;

}
.card-group img {
    min-height: 767px !important;
}

 */
.zdravec-card-content-box {
    /*margin-bottom: 27%; */
    padding: 40px 0 40px 0;
    width: 47%;
    background-color: #fffdfb;
    top: 50%;
    transform: translateY(-50%);
    bottom: initial;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    /*color: #1f9151*/
    color: #285a5b;
    margin-top: auto;
    margin-bottom: auto;
}
.zdravec-card-content-box a:hover {
    color:#80A09F;
}

.zdravec-card-products-content {
    width: 50%;
    position: relative;
}
.zdravec-card-content-box-inner {
    width: 100%;
}
.zdravec-products-colored-inner h2 {
    margin: 0px 0px 21px calc((100% - 93%) * 0.5);
    text-align: center;
}
.zdravec-svg-box {
    position: absolute;
    /*
    top: 4%;
    left:51%; */
    margin: 31px 5px -34px calc((100% - 5px) * 0.5);
    z-index: 10;
}
.btn-color-grn {
    background-color: #285a5b !important;
}

.btn-color-grn:focus {
    background-color: #285a5b !important;
}

.btn-color-orange {
    background-color: #ffb84d;
}


.btn-color-zeolite {
    background-color: #416c4f;
}

.btn-color-white {
    background-color: #fffdfb;
}

.zdravec-card-button, .zdravec-zeolite-button  {
    z-index: 999 !important;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    margin-top: 5%;
    text-transform: uppercase;
}
.zdravec-card-button a {
    color: #fffdfb;
    font: normal normal bold 13pt/1.4em Roboto-Thin,roboto,sans-serif;
    letter-spacing:0.05em;
}

.zdravec-zeolite-button a {
    color: #285a5b;
}

.btn-primary:hover {
    background-color: rgba(128, 160, 159, 0.58) !important;
    border-color: rgba(168, 184, 174, 1) !important;
}

/*** FOOTER ***/
.copy {
    padding-top: 30px;
}
.row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.smed i{
    color:#285a5b;
    font-size: 40px;
    margin: 10px;
    padding-top: 10px;
}
.smed i:hover {
    color:seagreen;
}
.faq {
    color:#285a5b;
}

footer a {
    color:#285a5b;
}

footer a:hover {
    color:#80A09F !important;
}

footer p {
    color: #285a5b !important;
    margin-bottom: 0;
    line-height: 2;
}
footer h4 {
    text-transform: uppercase;
}

footer {
    background: linear-gradient(#fffdfb, 85%, silver) !important;
}

/** Testimonials **/
#testimonials {
    background: #FFFDFB;
    background-image: url("../img/zdravec/zdravec-icons/zdravec_bg_small.png");
    /** parallax **/
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: cover; */
    /* Move the pseudo-element back away from the camera,
     * then scale it back up to fill the viewport.
     * Because the pseudo-element is further away, it appears to move more slowly, like in real life. */
    /*
    transform: translateZ(-1px) scale(1.048);

    transform: perspective(1px) translateZ(0);
    backface-visibility: hidden;*/
}
#testimonials .texts {
    color: #285a5b !important;
    transform:none !important;
}
#testimonials .carousel-item {
    color: #285a5b !important;
    transform:none !important;
}


#testimonials .carousel-item p {
    font-family: Roboto-Regular, Roboto,sans-serif;;
    font-size:13pt;
    margin-top: 2%;

}

#testimonials .carousel-inner {
    min-height: 200px;
}
.revs {
    padding: 50px;
}
.active {
    border:none;
}
.left {
    text-align: left;
}

/* Address */
.pft i {
    margin-right: 10px;
    color: #285a5b;
}
.pft .btn {
    float: right;
    margin-top: -25px;
    text-transform: none;
}

/* Contact */
#contact-cont {
   /* background-image: linear-gradient(#181174, #731397); */
    background-color: #ffb84d;
}
.text-green {
    color: #285a5b !important;
}

.text-red {
    color: darkred !important;
}

.text-white {
    color: #FFFDFB !important;;
}
.contact-inner {
    padding: 3rem !important;
}
.alert-success {
    display: none;
}
.rbtn {
    background-color: #285a5b;
    border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
    border: none;
    color: #FFFDFB;
    font: normal normal bold 13pt/1.4em roboto-thin,roboto,sans-serif;
    font-weight: lighter;
    letter-spacing:0.05em;
}
/* About */
.zdravec-content-text {
    background-color: #ffb84d;
}

.orange {
    background-color: #ffb84d;
}

.grn {
    background-color: #285a5b;
}

.zdravec-boza-content-text {
}

.zdravec-water-content-text {
    background-color: #285a5b;
}

.zdravec-ayran-content-text {
    background-color: #F6F8F8;
}

.zdravec-image-container {
    background-size: cover;
    max-width:100%;
    max-height:100%;
    min-height: 656px;
    height: auto;
    margin: 0 auto;
    position: relative;
    width: 100%;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
.about-image-first {
    background: url("../img/zdravec/zdravec-work-process-2-bw.png") no-repeat;
    background-size: cover;
}
.about-image-second {
    background: url("../img/zdravec/zdravec-work-process-bw.png") no-repeat;
    background-size: cover;
}

#about h2 {
    font-weight: bold;
}

.boza-image-first {
    background: url("../img/zdravec/used-img/boza/about_boza.jpg") no-repeat;
    background-size: cover;
}

.boza-image-second {
    background: url("../img/zdravec/used-img/boza/about_boza_all.jpg") no-repeat;
    background-size: cover;
}

.water-image-first {
    background: url("../img/zdravec/used-img/water/murgash-water-1.jpg") no-repeat;
    background-size: cover;
}

.water-image-second {
    background: url("../img/zdravec/used-img/water/zeo.jpg") no-repeat center;
    background-size: cover;
}

.ayran-image-first {
    background: url("../img/zdravec/used-img/ayran/ayran-1.jpg") no-repeat;
    background-size: cover;
}

.ayran-image-yova {
    background: url("../img/zdravec/used-img/ayran/ayran_yova.jpg") no-repeat;
    background-size: cover;
}

.container-fluid {
    padding-right: 0px !important;
    padding-left: 0px !important;
    margin-right:auto !important;
    margin-left:auto !important;
}

/** About text design **/
#about .texts {
    padding: 0 15% 0 15%;
}
#about {
    line-height:2;
    letter-spacing:1px;
    text-align: center;
}
#about .col-md-auto {
    padding: 8%;
}

.image-rounded {
    border-radius: 50%;
}

/** Contact **/
.contact-image-first {
    background: url("../img/zdravec/used-img/contact/contact_yova.jpeg") no-repeat;
    background-size: cover;
}

.contact-time-available {
    width: 75%;
}
.line {
    background-color: #285a5b;
    width: 1px !important;
    margin-top: 2rem;
    flex-grow:0;
    padding-left: 1px;
    padding-right: 1px;
}
.line-big {
    height: 100px;
}
.line-small {
    height: 70px;
}

.contact-hours {
    max-width: 48%;
    padding: 3rem 1rem 3rem 1rem !important;
    line-height: 2;
    font-size: medium;
}
.contact-image-second {
    background: url("../img/zdravec/used-img/home-grid-images/4CC9D76E-84AB-4F00-BE4E-5F88502E7061.jpeg") no-repeat;
    min-height: 900px;
}

.section-parallax {
    /* Set a specific height */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.form__group {
    position: relative;
    padding: 15px 15px 0;
    margin-top: 10px;
    width: 50%;
}

.form__group__large {
    position: relative;
    padding: 15px 15px 0;
    margin-top: 10px;
    width: 100%;
}

.form__field {
    font-family: inherit;
    width: 100%;
    border: 0;
    border-bottom: 2px solid #285a5b;
    outline: 0;
    font-size: 1.3rem;
    color: #285a5b;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}
::placeholder {
     color: transparent;
}

:placeholder-shown ~ .form__label {
     font-size: 1.3rem;
     cursor: text;
     top: 20px;
 }


.form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #285a5b;
}

.form__field:focus + .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #285a5b;
    font-weight:700;
    padding-bottom: 6px;
    font-weight: 700;
    border-width: 3px;
    /*border-image: linear-gradient(to right, #11998e,#38ef7d);*/
    border-image-slice: 1;
}


/* reset input */
.form__field + :required {
    box-shadow:none;
}
.form__field + :invalid {
    box-shadow:none;
}

/** Scroll Top */
.scrollTop {
    position: fixed;
    width: 50px;
    bottom: 20px;
    right: 15px;
    align-items: center;
    height: 40px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.7;
}

.scrollTop:hover{
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

@media only screen and (max-width: 1750px) {
    #about {
        line-height:1.6;
        letter-spacing:1px;
    }
}


@media only screen and (max-width: 1600px) {
    .carousel-caption {
        /* margin-bottom: 27%;
        width: 60%;
        */
    }

    .card-title {
        margin-bottom: 0;
    }

    .zdravec-card-products-content {
        width: 70%;
    }

    #about {
        line-height:1.5;
        letter-spacing:1px;
    }

    .pad {
        padding: 1rem !important;
    }
}

@media only screen and (max-width: 1300px) {
    .carousel-caption {
        /*  margin-bottom: 24%; */
        width: 75%;
    }

    .carousel-caption h1 {
        font-size: 65pt;
    }
}

@media only screen and (min-width: 558px) and (max-width: 992px) {
    .ayran-image-first {
        background: url("../img/zdravec/used-img/ayran/ayran-1-cut.jpeg") no-repeat;
        background-size: cover;
    }

    .ayran-image-yova {
        background: url("../img/zdravec/used-img/ayran/ayran_yova_cut.jpeg") no-repeat;
        background-size: cover;
    }

    .boza-image-first {
        background: url("../img/zdravec/used-img/boza/about_boza_cut.jpeg") no-repeat;
        background-size: cover;
    }

    .boza-image-second {
        background: url("../img/zdravec/used-img/boza/about_boza_all_cut.jpeg") no-repeat;
        background-size: cover;
    }

    .water-image-first {
        background: url("../img/zdravec/used-img/water/murgash-water-1-cut.jpeg") no-repeat;
        background-size: cover;
    }

    .zdravec-image-container {
        min-height: 876px;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px) {
    .zdravec-card-content-box {
        padding: 25px 0 25px 0;
        width: 55%;
    }
}


@media only screen and (max-width: 1200px) {
    .zdravec-products-colored-inner h2 {
        margin: 0px 0px 41px calc((100% - 93%) * 0.5);
        text-align: center;
    }

    .carousel-caption {
        /*  margin-bottom: 24%; */
        width: 70%;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1300px) {
    .zdravec-card-content-box {
        padding: 15px 0 15px 0;
        width: 65%;
    }
    /** About text design **/
    #about .texts {
        padding: 0 10% 0 10%;
    }
    #about {
        line-height: 1;
        letter-spacing:1px;
    }
    #about .col-md-auto {
        padding: 5%;
    }
}

@media only screen and (max-width: 1110px) {
    .carousel-caption {
        width: 70%;
        padding: 25px;
    }
    .section-card {
        width: 100%;
        margin-top: 0;
    }
    .card-group > .card {
        margin-bottom: 0;
    }

    .zdravec-card-products-content {
        width: 80%;
    }
    .zdravec-card-button {
        margin-top: 10%;
    }
    .zdravec-products-colored-inner h2 {
        margin: 0px 0px 10px calc((100% - 93%) * 0.5);
        text-align: center;
    }
    .card p {
        line-height: 1.3em;
    }

    #about h2, .card h2 {
        font-size: 26px;
    }

    h3 {
        font-size: 24px;
    }


    #about .texts {
        padding: 0 8% 0 8%;
    }
}
@media (max-width: 992px) {
    .pad {
        padding: 1rem !important;
    }

    .zdravec-content-text {
        min-height: 576px;
    }
    #navbar-nav-links a {
        margin-right: 0px;
    }
    /** The Boza **/
    .zdravec-boza-content-text {
        min-height: 576px;
    }
    .zdravec-water-content-text {
        min-height: 576px;
    }

    .zdravec-ayran-content-text {
        min-height: 576px;
    }

    #about {
        text-align: center;
        font-size: 1.3rem;
        line-height: 1.3;
        letter-spacing:1px;
    }

    .revs {
        padding: 2rem 0 2rem 0 !important;
    }
    .contact-inner {
        padding: 2rem 0 2rem 0 !important;
    }

    #about .col-md-auto {
        padding: 5%;
    }

    #about .texts {
        padding: 0 5% 0 5%;
    }
    h2 {
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-weight: bolder !important;
    }
}


@media only screen and (max-width: 992px) {
    .carousel-caption {
        width: 65%;
    }

    .logo-header {
        height: 72px;
        width: 72px;
    }

    /* about us change and remove padding */
    .about-container {
        display: flex !important;
        flex-wrap: wrap !important;
        padding: 2rem 0 2rem 0 !important;

    }

    /*  Elements Animation */
    .from-bottom.animatable {
        opacity: 0;
        -moz-transform: translate(0, 75px);
        -webkit-transform: translate(0, 75px);
        transform: translate(0, 75px);
        -moz-transition: all .8s ease 0s;
        -webkit-transition: all .8s ease 0s;
        transition: all .8s ease 0s;
    }
    .from-bottom.animate {
        opacity: 1;
        -moz-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .from-left.animatable {
        opacity: 0;
        -moz-transform: translate(-100%, 0);
        -webkit-transform: translate(-100%, 0);
        transform: translate(-100%, 0);
        -moz-transition: all .8s ease 0s;
        -webkit-transition: all .8s ease 0s;
        transition: all .8s ease 0s;
    }
    .from-left.animate {
        opacity: 1;
        -moz-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .from-right.animatable {
        opacity: 0;
        -moz-transform: translate(100%, 0);
        -webkit-transform: translate(100%, 0);
        transform: translate(100%, 0);
        -moz-transition: all .8s ease 0s;
        -webkit-transition: all .8s ease 0s;
        transition: all .8s ease 0s;
    }
    .from-right.animate {
        opacity: 1;
        -moz-transform: translate(0, 0);
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
    .twirl-up.animatable {
        opacity: 0;
        -moz-transform: scale(.1, .1) rotate(-360deg);
        -webkit-transform: scale(.1, .1) rotate(-360deg);
        transform: scale(.1, .1) rotate(-360deg);
        -moz-transition: all 1s ease 0s;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
    }
    .twirl-up.animate {
        opacity: 1;
        -moz-transform: scale(1, 1) rotate(0deg);
        -webkit-transform: scale(1, 1) rotate(0deg);
        transform: scale(1, 1) rotate(0deg);
    }


    /** TODO Stefy  change img size to proper one */
    /*
    .card-group img {
        max-height: 576px !important;
    }

    .card-group img {
        min-height: 50% !important;
    }
     */
}

@media only screen and (max-width: 880px) {
    .carousel-caption {
        width: 72%;
    }

}

@media only screen and (max-width: 777px) {
    .carousel-caption {
        width: 70%;
        padding: 25px;
    }
    .carousel-caption h3{
        font-size: 20px;
    }

    .carousel-caption h1{
        font-size: 70px;
    }
    .carousel-caption p {
        font-size: 15px;
        font-weight: bold;
    }
}

@media only screen and (max-width: 768px) {
    .logo-header {
        height: 64px;
        width: 64px;
    }

    footer > .row > .col-md-3 {
        margin-bottom: 30px;
    }
    footer > .row > .col-md-3 > div {
        text-align: center !important;
    }
    footer p {
        line-height: 1.4;
    }
    .line-big {
        height: 120px;
    }

    .contact-inner {
        padding: 0 !important;
    }
    .about-container {
        padding: 0 !important;
    }
    .carousel-caption {
        width:82%;
        left:0;
        right: 0;
    }
}


@media (max-width: 500px) {
    .scrollTop {
        height: 30px !important;
        width: 30px;
        bottom: 10px;
        right: 10px;
    }
    .carousel-caption {
        width: 87%;
        left:0;
        right: 0;
    }
    .navbar {
        padding-top: 0.2rem;
        padding-right: 0.8rem;
        padding-bottom: 0.2rem;
        padding-left: 0.8rem;
    }

    #about .texts {
        padding: 0 3% 0 3%;
    }

    .pad {
        padding: 0.3rem !important;
    }

    .carousel-caption h1 {
        font-size: 45px;
        text-align: center !important;
        width: 100%;
    }
    .home-products .card {
        min-height: 332px;
    }

    /** TODO max height does not work change with image resize **/
    .card-group img {
        min-height: 332px !important;
    }

    .zdravec-card-content-box {
        padding: 3px 0 10px 0;
        width: 70%;
        height: 130px;
        bottom: initial;
        margin-top:35%;
        margin-bottom: 0 !important;
    }

    .zdravec-card-content-box > div {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top:35%;
        /* margin-bottom: 0 !important; moves inner div height ups and down, now is centered*/
    }

    #about h2, .card h2 {
        font-size: 15pt;
        line-height: 0.9em;
        letter-spacing:0.03em;
        font-weight: 500;
    }

    h3 {
        font-size: 15pt;
        line-height: 0.9em;
        letter-spacing:0.02em;
        font-weight: 500;
    }

    .zdravec-card-products-content {
        width: 90%;
    }
    .zdravec-products-colored-inner > .zdravec-card-button {
        margin-top: 20px;
    }
    .card p {
        line-height: 1.3;
    }

    .zdravec-products-colored-inner h2 {
        margin: 0px 0px 30px calc((100% - 93%) * 0.5);
    }
    /* about us */
    .zdravec-content-text {
        padding: 2rem 0.5rem 2rem 0.5rem !important;
    }

    /** The Boza **/
    .zdravec-boza-content-text {
        padding: 2rem 0.5rem 2rem 0.5rem !important;
    }

    .zdravec-water-content-text {
        padding: 2rem 0.5rem 2rem 0.5rem !important;
    }

    .zdravec-ayran-content-text {
        padding: 2rem 0.5rem 2rem 0.5rem !important;
    }

    .revs {
        padding: 2rem 0.5rem 2rem 0.5rem !important;
    }
    #about {
        font-size: 1.1rem;
    }
    /* footer */
    footer > .row > .col-md-3 {
        margin-bottom: 30px;
    }
    footer > .row > .col-md-3 > div {
        text-align: center !important;
    }
    footer p {
        line-height: 1.4;
    }
    footer h4 {
        padding-bottom: 1.1rem !important;
    }

    #about .col-md-auto {
        padding: 1% 1% 3% 1%;
    }
    #testimonials .carousel-inner {
        min-height: 150px;
    }
}

@media only screen and (max-width: 400px) {
    .contact-time-available {
        width: 90%;
    }
}

@media only screen and (max-width: 360px) {
    .contact-hours {
        padding: 1rem 1rem 1rem 1rem !important;
        line-height: 2;
        font-size: 0.8rem;
    }
    .line {
        margin-top: 0rem;
    }
    .form__field {
        font-size: 1rem;
    }

    #contact .form__group {
        padding: 18px 18px;
    }
    #contact .form__group__large {
        padding: 18px 18px;
    }
    .zdravec-card-button {
        margin-top: 3%;
    }

    #about .texts {
        padding: 0 1% 0 1%;
    }
}
@media only screen and (max-width: 320px) {
    .line-big {
        height: 140px;
    }
}

/* Images Grid */

img,
.card-img-top {
    border-radius: 0em;
}

@media (max-width: 576px) {
    .card-columns {
        column-count: 3 !important;
    }
}

@media (min-width: 768px) {
    .card-columns {
        column-count: 3 !important;
    }
}

@media (min-width: 992px) {
    .card-columns {
        column-count: 4 !important;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        column-count: 5 !important;
    }

    .zdravec-water-content-text.pad {
        padding: 4rem !important;
    }
}
@media (min-width: 1326px) {
    #contact .zdravec-content-text {
        padding: 8rem 5rem 8rem 5rem !important;
    }

    .zdravec-ayran-content-text.pad {
        padding: 3rem !important;
    }
    .zdravec-water-content-text.pad {
        padding: 4rem !important;
    }
}

@media (min-width: 1526px) {
    #contact .zdravec-content-text {
        padding: 14rem 5rem 14rem 5rem !important;
    }
}

/** PRoducts **/

a:hover,a:focus{
    text-decoration: none;
    outline: none;
}
#products-container .nav-tabs{
    border: none !important;
    border-bottom: 2px solid #285a5b !important;
    margin: 0 !important;
}
#products-container .nav-tabs a{
    padding: 10px 20px;
    margin: 0 10px -1px 0;
    font-size: 17px;
    font-weight: 600;
    color: #285a5b;
    text-transform: uppercase;
    text-transform: uppercase;
    border: 2px solid #e6e5e1 !important;
    border-bottom: none !important;
    border-radius: 5px 5px 0 0 !important;
    z-index: 1;
    position: relative;
    transition: all 0.3s ease 0s;
}

#products-container .nav-tabs  a:hover,
#products-container .nav-tabs a.active {
    background: #fff;
    color: #285a5b !important;
    border: 2px solid #285a5b !important;
    border-bottom-color: transparent !important;
}

#products-container .nav-tabs a:before{
    content: "";
    display: block;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: -2px !important;
    left: 0;
    right: 0;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}
#products-container .nav-tabs .active a:before,
#products-container .nav-tabs a:hover:before{ transform: scaleX(1); }
#products-container .tab-content{
    font-size: 17px;
    color: #6f6f6f;
    line-height: 30px;
    letter-spacing: 1px;
    position: relative;
}
@media only screen and (max-width: 479px){
    #products-container .nav-tabs{ border: none; }
    #products-container .nav-tabs a{
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
    }
    #products-container .nav-tabs a{
        margin: 0 !important;
        border-bottom: 2px solid transparent !important;
    }
    #products-container .nav-tabs  a:before{
        content: "";
        width: 100%;
        height: 2px;
        background: #285a5b;
        position: absolute;
        bottom: -2px;
        left: 0;
    }
}

.nav-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 0px;
}

